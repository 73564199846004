import React from "react";

import { useLocation } from "react-router-dom";

import Box from "../../bp-ui/components/Box";
import Link from "../../bp-ui/components/Link";
import Typography from "../../bp-ui/components/Typography";
import Pages from "../../util/pages";
import { getSharedQueryParamsString } from "../util/url";

export const SignUpText = () => {
  const location = useLocation();
  return (
    <Box
      sx={{
        marginTop: "1rem",
      }}
    >
      <Typography>
        Don’t have an account?{" "}
        <Link
          href={Pages.register + getSharedQueryParamsString(location, true)}
        >
          Sign up
        </Link>
      </Typography>
    </Box>
  );
};
