import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { BaseTheme } from "./bp-ui/BaseTheme";
import { withAuth } from "./components/hoc/withAuth";
import { withoutAuth } from "./components/hoc/withoutAuth";
import AppConfig from "./components/shared/AppConfig";
import EmailVerifiedPage from "./pages/EmailVerified.page";
import ForgotPasswordPage from "./pages/ForgotPassword.page";
import GlobalEditPage from "./pages/GlobalEdit.page";
import LoginPage from "./pages/Login.page";
import NotFoundPage from "./pages/NotFound.page";
import RegistrationPage from "./pages/Registration.page";
import ResetPasswordPage from "./pages/ResetPassword.page";
import AccountSettingsPage from "./pages/settings/AccountSettings.page";
import EmailEditPage from "./pages/settings/EmailEdit.page";
import EmailVerifyPage from "./pages/settings/EmailVerify.page";
import PasswordEditPage from "./pages/settings/PasswordEdit.page";
import ProfileEditPage from "./pages/settings/ProfileEdit.page";
import SwaggerPage from "./pages/Swagger.page";
import ThirdPartyAuthorizationPage from "./pages/ThirdPartyAuthorization.page";
import UpdateEmailNotificationPage from "./pages/UpdateEmailNotificationPage";
import Pages from "./util/pages";

const queryClient = new QueryClient();

function App() {
  return (
    <BaseTheme>
      <AppConfig>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              <Route path={Pages.home} Component={withoutAuth(LoginPage)} />
              <Route
                path={Pages.register}
                Component={withoutAuth(RegistrationPage)}
              />
              <Route
                path={Pages.forgotPassword}
                Component={withoutAuth(ForgotPasswordPage)}
              />
              <Route path={Pages.resetPassword} Component={ResetPasswordPage} />
              <Route path={Pages.emailVerified} Component={EmailVerifiedPage} />
              <Route
                path={Pages.settings}
                Component={withAuth(AccountSettingsPage)}
              />
              <Route
                path={Pages.emailEdit}
                Component={withAuth(EmailEditPage)}
              />
              <Route
                path={Pages.emailVerify}
                Component={withAuth(EmailVerifyPage)}
              />
              <Route
                path={Pages.passwordEdit}
                Component={withAuth(PasswordEditPage)}
              />
              <Route
                path={Pages.profileEdit}
                Component={withAuth(ProfileEditPage)}
              />
              <Route path={Pages.edit} Component={withAuth(GlobalEditPage)} />
              <Route path="*" Component={NotFoundPage} />
              <Route path={Pages.docs} Component={SwaggerPage} />
              <Route
                path={Pages.thirdPartyAuthorization}
                Component={withAuth(ThirdPartyAuthorizationPage)}
              />
              <Route
                path={Pages.updateEmailNotification}
                Component={withAuth(UpdateEmailNotificationPage)}
              />
            </Routes>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppConfig>
    </BaseTheme>
  );
}

export default App;
