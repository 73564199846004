import React, { useRef, useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import GoBack from "./GoBack";
import {
  is401ClientError,
  is417ClientError,
  is429ClientError,
  is4xxClientError,
  isHttpClientError,
} from "../../api/httpClient";
import { sendVerificationEmail, updateEmail } from "../../api/settings";
import { validateCredentials } from "../../api/validation";
import Box from "../../bp-ui/components/Box";
import Link from "../../bp-ui/components/Link";
import Typography from "../../bp-ui/components/Typography";
import { User } from "../../models/user";
import { currentUserQueryKey } from "../../queries/useCurrentUser";
import {
  EMAIL_ALREADY_EXISTS_ERROR_MSG,
  EMAIL_NOT_VALID_ERROR_MSG,
} from "../../util/errorMessages";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import useAuthCurrentUserQuery from "../../util/useAuthCurrentUserQuery";
import EmailField from "../account/EmailField";
import ErrorAlert from "../shared/ErrorAlert";
import Generic4xxErrorAlert from "../shared/Generic4xxErrorAlert";
import LimitErrorAlert from "../shared/LimitErrorAlert";
import PrimaryButton from "../shared/PrimaryButton";
import TextAction from "../shared/TextAction";
import Unauthorized from "../shared/Unauthorized";
import { basicEmailValidation } from "../util/account-fields-validation";
import { mobileScreenSx } from "../util/screen";
import { getNext, navigateToLocation } from "../util/url";

const isEmailEmpty = (email: string | null) => {
  return email == null || email == "";
};

const getEmailLabelText = (currentUser: User): string => {
  if (isEmailEmpty(currentUser.email) || currentUser.emailConfirmed)
    return "Email";
  return "Email (unverified)";
};

const EmailEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const currentUser = useAuthCurrentUserQuery(navigate, location, queryClient);

  const [email, setEmail] = useState(currentUser?.email || "");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showGenericErrorAlert, setShowGenericErrorAlert] = useState(false);
  const [showLimitErrorAlert, setShowLimitErrorAlert] = useState(false);

  const emailValidationInProgressRef = useRef(false);

  const setValues = (value: string, error: boolean, errorMessage: string) => {
    setEmail(value);
    setError(error);
    setErrorMessage(errorMessage);
  };

  const { mutate: mutateValidateEmail, isPending: isValidateEmailLoading } =
    useMutation({
      mutationFn: async (value: string) => {
        if (error) {
          return;
        }
        emailValidationInProgressRef.current = true;
        const isValid = basicEmailValidation(value);

        if (!isValid) {
          throw new Error("Invalid email.");
        }

        await validateCredentials("email", value);
      },
      onSettled(_data, error) {
        if (error) {
          let errorMessage = EMAIL_NOT_VALID_ERROR_MSG;

          if (
            isHttpClientError(error) &&
            is417ClientError(error as AxiosError)
          ) {
            errorMessage = EMAIL_ALREADY_EXISTS_ERROR_MSG;
          }
          setValues(email, true, errorMessage);
          return;
        }
        emailValidationInProgressRef.current = false;
        handleUpdate();
      },
    });

  const isAuthFlow = (next: string) => {
    const authRegex = /\/authorize\S*/;
    return authRegex.test(next);
  };

  const updateEmailMutation = useMutation({
    mutationFn: async () => {
      await updateEmail(email);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
      const next = getNext(location);
      if (next !== null && isAuthFlow(next)) {
        navigateToLocation(next);
      } else {
        setEmailVerifyScreen();
      }
    },
    onError: (error: AxiosError) => {
      if (is401ClientError(error)) {
        queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
        navigate(Pages.home);
      }
      if (is4xxClientError(error)) {
        setShowGenericErrorAlert(true);
      }
    },
  });

  const resendConfirmationEmailMutation = useMutation({
    mutationFn: async () => {
      await sendVerificationEmail();
    },
    onSuccess: () => {
      setValues(email, false, "");
      setEmailVerifyScreen();
    },
    onError: (error: AxiosError) => {
      if (is401ClientError(error)) {
        queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
        navigate(Pages.home);
      }
      if (is429ClientError(error)) {
        setShowLimitErrorAlert(true);
        setShowGenericErrorAlert(false);
      } else if (is4xxClientError(error)) {
        setShowGenericErrorAlert(true);
        setShowLimitErrorAlert(false);
      }
    },
  });

  const handleUpdate = () => {
    if (emailValidationInProgressRef.current === true) {
      return;
    }
    if (error) {
      return;
    }
    updateEmailMutation.mutate();
  };

  const handleSetEmail = (value: string) => {
    if (value.length === 0) {
      setValues(value, true, EMAIL_NOT_VALID_ERROR_MSG);
      return;
    }

    setValues(value, false, errorMessage);
  };

  const onSave = async () => {
    setShowGenericErrorAlert(false);
    await mutateValidateEmail(email);
  };

  const resendConfirmationEmail = () => {
    setShowGenericErrorAlert(false);
    resendConfirmationEmailMutation.mutate();
  };

  const navigateTo = (path: string) => {
    invalidateQueriesAndNavigateTo(path, queryClient, navigate, location);
  };

  const navigateToSettings = () => {
    const next = getNext(location);
    if (next !== null && isAuthFlow(next)) {
      navigateToLocation(next);
    } else {
      navigateTo(Pages.settings);
    }
  };

  const setEmailVerifyScreen = () => navigateTo(Pages.emailVerify);

  if (currentUser === null) {
    return <Unauthorized />;
  }

  return (
    <Box
      sx={{
        width: "24rem",
        ...mobileScreenSx({
          width: "100%",
          paddingTop: "2rem",
        }),
      }}
    >
      <GoBack
        onClick={navigateToSettings}
        customSx={{ paddingBottom: "1rem" }}
      />
      <Typography component="h1" variant="h3">
        Update email
      </Typography>
      <Box
        sx={{
          ...mobileScreenSx({
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "1.5rem",
          }),
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "500",
            height: "1.5rem",
            paddingTop: "1rem",
            display: "none",
            ...mobileScreenSx({
              display: "block",
            }),
          }}
          className="sentry-mask"
        >
          {"Current email: " + currentUser.email}
        </Typography>
        {!currentUser.emailConfirmed && !isEmailEmpty(currentUser.email) && (
          <ErrorAlert
            customSx={{ marginTop: "1.625rem" }}
            message={
              <>
                This email is currently unverified.{" "}
                <Link color="inherit" onClick={resendConfirmationEmail}>
                  Resend verification.
                </Link>
              </>
            }
          />
        )}
      </Box>
      {showGenericErrorAlert && (
        <Generic4xxErrorAlert customSx={{ marginTop: "1.625rem" }} />
      )}
      {showLimitErrorAlert && (
        <LimitErrorAlert customSx={{ marginTop: "1.625rem" }} />
      )}
      <EmailField
        label={getEmailLabelText(currentUser)}
        value={email}
        error={error}
        helperText={errorMessage}
        placeholder={currentUser.email}
        onChange={handleSetEmail}
        isVerified={currentUser.emailConfirmed}
        sx={{ marginY: "1.5rem" }}
      />
      <PrimaryButton
        type="button"
        label="Save"
        onClick={onSave}
        isLoading={isValidateEmailLoading}
        isDisabled={currentUser.email === email}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: "2rem",
        }}
      >
        <TextAction text="Cancel" onClick={navigateToSettings} />
      </Box>
    </Box>
  );
};

export default EmailEdit;
