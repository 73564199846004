export default class Pages {
  static readonly home = "/";
  static readonly register = "/register";
  static readonly forgotPassword = "/forgot-password";
  static readonly resetPassword = "/reset-password";
  static readonly emailVerified = "/verify-email";
  static readonly settings = "/settings";
  static readonly emailEdit = "/settings/email";
  static readonly emailVerify = "/settings/verify";
  static readonly passwordEdit = "/settings/password";
  static readonly profileEdit = "/settings/profile";
  static readonly edit = "/edit/:userId";
  static readonly docs = "/docs";
  static readonly thirdPartyAuthorization = "/third-party-authorization";
  static readonly updateEmailNotification = "/update-email";
}
